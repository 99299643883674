import React, { useState } from 'react';
import axios from 'axios'; // Import axios for HTTP requests

function Intake() {

  const API_ENDPOINT = process.env.REACT_APP_SERVER_HOST || 'http://localhost:9090'

  const [email, setEmail] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(new Date(1990, 1, 20).toISOString().slice(0, 10));
  const [sex, setSex] = useState('');
  const [height, setHeight] = useState('5\'5');
  const [weight, setWeight] = useState('');
  const [city, setCity] = useState(''); // State for city input
  const [jobTitle, setJobTitle] = useState(''); // State for job title input
  const [company, setCompany] = useState(''); // State for company input (optional)
  const [relationshipStatus, setRelationshipStatus] = useState(''); // State for relationship status
  const [parentalStatus, setParentalStatus] = useState(''); // State for parental status
  const [personalityType, setPersonalityType] = useState(''); // State for parental status

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'email':
        console.log("Email: " + value)
        setEmail(value);
        break;
      case 'dob':
        console.log("dob: " + value)
        setDateOfBirth(value);
        break;
      case 'sex':
        console.log("sex: " + value)
        setSex(value);
        break;
      case 'height':
        console.log("height: " + value)
        setHeight(value);
        break;
      case 'weight':
        console.log("weight: " + value)
        setWeight(value);
        break;       
      case 'city':
        setCity(value);
        break;
      case 'jobTitle':
        setJobTitle(value);
        break;
      case 'company':
        setCompany(value); // Optional field
        break;
      case 'relationshipStatus':
        console.log("Rel: " + value)
        setRelationshipStatus(value);
        break;
      case 'parentalStatus':
        console.log("Par: " + value)
        setParentalStatus(value);
        break;
      case 'personalityType':
        console.log("Personality type: " + value)
        setPersonalityType(value);
        break;
      default:
        break;
    }
  };

  

  const relationshipOptions = [
    '',
    'Single',
    'Married',
    'In a Relationship',
    'Divorced',
    'Widowed',
  ];

  const parentalOptions = [
    '',
    'No Children',
    'Has Children',
    'Planning to Have Children',
  ];




  const generateHeightOptions = () => {
    const options = [];
    for (let feet = 4; feet < 7; feet++) {
      for (let inches = 0; inches <= 11; inches += 1) { // Iterate every 6 inches
        const height = `${feet}'${inches ? inches : ''}`;
        options.push(<option key={height} value={height}>{height}</option>);
      }
    }
    return options;
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    const userData = {
      email,
      dateOfBirth,
      sex,
      height,
      weight,
      city,
      jobTitle,
      company,
      relationshipStatus,
      parentalStatus,
      personalityType,
    };

    try {
      const response = await axios.post(API_ENDPOINT + '/api/users', userData); // Send POST request
      console.log('User data submitted:', response.data);
      // Handle successful submission (e.g., clear form, show success message)
    } catch (error) {
      console.error('Error submitting user data:', error);
      // Handle errors (e.g., display error message to user)
    }
  };


  return (
    <div className="about-page container">

    <div className="intake-form">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="input-email">Email address</label>
          <input type="email" className="form-control" id="email" name="email" value={email} aria-describedby="emailHelp" placeholder="Enter email" onChange={handleInputChange}/>
          <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
        </div>

        <br />

        <div className="form-group">
          <label htmlFor="dob">What is your date of birth?</label>
          <input type="date" className="form-control" id="dob" name="dob" min="1930-12-31" max="2010-12-31" onChange={handleInputChange} defaultValue="1990-02-20" />
        </div>

        <br />

        What is your assigned sex at birth? <br />
        <div className="form-check form-check-inline">
          <input className="form-check-input" type="radio" name="sex" id="inlineRadio1" value="male" onChange={handleInputChange}/>
          <label className="form-check-label" htmlFor="inlineRadio1">Male</label>
        </div>
        <div className="form-check form-check-inline">
          <input className="form-check-input" type="radio" name="sex" id="inlineRadio2" value="female" onChange={handleInputChange}/>
          <label className="form-check-label" htmlFor="inlineRadio2">Female</label>
        </div>

        <br />
        <br />

        <div className="row">
          <div className="col">
            <label htmlFor="height-select">Height</label>
            <select
              className="form-control"
              id="height-select"
              name="height"
              value={height}
              onChange={handleInputChange}
            >
              {generateHeightOptions()}
            </select>
          </div>

          <div className="col">
            <label htmlFor="weight-select">Weight (lbs)</label>
            <input type="number" className="form-control" id="weight-select" name="weight" placeholder="130" min="60" max="800" onChange={handleInputChange}/>
          </div>

          <div className="col">
          <label htmlFor="relationshipStatus">Relationship Status</label>
          <select
            className="form-control"
            name="relationshipStatus"
            id="relationshipStatus"
            onChange={handleInputChange}
          >
            {relationshipOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>

        <div className="col">
          <label>Parental Status</label>
          <select
            className="form-control"
            name="parentalStatus"
            id="parentalStatus"
            value={parentalStatus}
            onChange={handleInputChange}
          >
            {parentalOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div> 
        </div>

        <br />

      <div className="row">
        <div className="col-md-3 col-sm-6">
          <label htmlFor="city">City</label>
          <input
            className="form-control"
            type="text"
            name="city"
            id="city"
            value={city}
            onChange={handleInputChange}
          />
        </div>

        <div className="col-md-3 col-sm-6">
          <label htmlFor="jobTitle">Job Title</label>
          <input
            className="form-control"
            type="text"
            name="jobTitle"
            id="jobTitle"
            value={jobTitle}
            onChange={handleInputChange}
          />
        </div>

        <div className="col-md-3 col-sm-12">
          <label htmlFor="company">Company (Optional)</label>
          <input
            className="form-control"
            type="text"
            name="company"
            id="company"
            value={company} // Optional field
            onChange={handleInputChange}
          />
        </div>

        <div className="col-md-3 col-sm-12">
          <label htmlFor="company">Personality Type (Optional)</label>
          <select
            className="form-control"
            name="personalityType"
            id="personalityType"
            value={personalityType}
            onChange={handleInputChange}
          >
              <option key="" value=""></option>
              <option key="INFP" value="INFP">INFP</option>
              <option key="INTJ" value="INTJ">INTJ</option>
              <option key="INFJ" value="INFJ">INFJ</option>
              <option key="INTP" value="INTP">INTP</option>
              <option key="ENFP" value="ENFP">ENFP</option>
              <option key="ENTJ" value="ENTJ">ENTJ</option>
              <option key="ENTP" value="ENTP">ENTP</option>
              <option key="ENFJ" value="ENFJ">ENFJ</option>
              <option key="ISFJ" value="ISFJ">ISFJ</option>
              <option key="ISFP" value="ISFP">ISFP</option>
              <option key="ISTJ" value="ISTJ">ISTJ</option>
              <option key="ISTP" value="ISTP">ISTP</option>
              <option key="ESFJ" value="ESFJ">ESFJ</option>
              <option key="ESFP" value="ESFP">ESFP</option>
              <option key="ESTJ" value="ESTJ">ESTJ</option>
              <option key="ESTP" value="ESTP">ESTP</option>
        
 
          </select>
        </div>
      </div>




        <div className="form-check form-check-inline">
          <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"/>
          <label className="form-check-label" htmlFor="inlineCheckbox1">1</label>
        </div>
        <div className="form-check form-check-inline">
          <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"/>
          <label className="form-check-label" htmlFor="inlineCheckbox2">2</label>
        </div>
        <div className="form-check form-check-inline">
          <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"/>
          <label className="form-check-label" htmlFor="inlineCheckbox2">2</label>
        </div>
        <div className="form-check form-check-inline">
          <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"/>
          <label className="form-check-label" htmlFor="inlineCheckbox2">2</label>
        </div>
        <div className="form-check form-check-inline">
          <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"/>
          <label className="form-check-label" htmlFor="inlineCheckbox2">2</label>
        </div>
        <div className="form-check form-check-inline">
          <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"/>
          <label className="form-check-label" htmlFor="inlineCheckbox2">2</label>
        </div>
        <div className="form-check form-check-inline">
          <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"/>
          <label className="form-check-label" htmlFor="inlineCheckbox2">2</label>
        </div>


        <br />
        <br />

        <div className="form-group">
          <label htmlFor="exampleFormControlTextarea1">Is there anything else we should know?</label>
          <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
        </div>

        <br />

        <div className="form-check">
          <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
          <label className="form-check-label" htmlFor="exampleCheck1">I acknowledge that ThreeDaily cannot make me live forever and that all generated strategies and tasks are merely suggestions with no guaranteed outcome.</label>
        </div>

        <br />

        <button type="submit" className="btn btn-primary">Submit</button>
      </form>
    </div>


    </div>
  );
}

export default Intake;
