import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DisplayGoals from './DisplayGoals';

import high_five_icon from '../resources/high-five-people-icon-transparent.png';

const Tasks = () => {

  const API_ENDPOINT = process.env.REACT_APP_SERVER_HOST || 'http://localhost:9090'

  //const email = localStorage.getItem('email')
  const uid = localStorage.getItem('uid')

  const [response, setResponse] = useState('');


  const [tasks, setTasks] = useState([]);
  const [userRank, setUserRank] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const today = new Date()

  const activeTasks = tasks.filter((task) => {
    const taskDate = new Date(task.date).getDate();
    return taskDate === today.getDate() && task.status === 0 && task.show_again === 1;
  });
  
  
  const incompleteTasks = tasks.filter((task) => {
    const taskDate = new Date(task.date).getDate();
    return taskDate < today.getDate() && task.status === 0 && task.show_again === 1;
  });

  const completedTasks = tasks.filter((task) => task.status === 1);

  const blockedTasks = tasks.filter((task) => task.show_again === 0);

  function calculateContinuousDaysFromToday(tasks) {
    // Sort tasks by date in descending order (to start from today)

    //console.log("Tasks are: " + JSON.stringify(tasks))
    const sortedTasks = tasks.slice().sort((a, b) => new Date(b.date) - new Date(a.date));

    //console.log("Sorted Tasks are: " + JSON.stringify(sortedTasks))
  
    // Get today's date
    const today = new Date();
  
    // Initialize variables
    let currentStreak = 0;
    let previousDate = null;

    
    if(sortedTasks[0]){
      const firstDate = new Date(sortedTasks[0].date);
      /*
      console.log(firstDate.getDate())
      console.log(today.getDate())
      console.log(firstDate.getDate() < today.getDate())
      */
      if(firstDate.getDate() < today.getDate()){
        return 0;
      }
    }

    
  
    for (const task of sortedTasks) {
      const taskDate = new Date(task.date);
  
      // Check if the task is from today or earlier
      if (taskDate <= today) {
        //console.log(`Task Date: ${taskDate.toLocaleDateString()} Current streak: ${currentStreak}`);
        // Check if it's the first task or a consecutive day
        if (!previousDate || previousDate.getDate() === taskDate.getDate() + 1) {
          currentStreak++;
        } else if (previousDate.getDate() === taskDate.getDate()) {
            //do nothing
        } else {
          break
        }
  
        previousDate = taskDate;
  
        // No break statement needed here - process all tasks
      }
    }
  
    return currentStreak;
  }

  function calculateContinuousDays(tasks) {
    // Sort tasks by date in ascending order
    const sortedTasks = tasks.slice().sort((a, b) => new Date(a.date) - new Date(b.date));

    // Initialize variables
    let currentStreak = 0;
    let maxStreak = 0;
    let previousDate = null;

    for (const task of sortedTasks) {
      const currentDate = new Date(task.date);

      // Check if it's the first task or a consecutive day with completed task
      if (!previousDate || (previousDate.getDate() === currentDate.getDate() - 1 && task.status === 1)) {
        currentStreak++;
      } else {
        currentStreak = task.status === 1 ? 1 : 0; // Reset if not consecutive or not completed
      }

      maxStreak = Math.max(maxStreak, currentStreak);
      previousDate = currentDate;
    }

    return maxStreak;
  }
  

  function formatDateMonthDay(dateString) {
    // Create a Date object from the provided string
    const dateObject = new Date(dateString);
  
    // Extract month and day for formatting
    const month = dateObject.toLocaleString('default', { month: 'long' });
    const day = dateObject.getDate();
  
    // Format the output as "Month Day"
    return `${month} ${day}`;
  }


  const groupedTasks = tasks.filter((task) => task.status === 0).reduce((groups, task) => {
    const date = formatDateMonthDay(task.date);
    groups[date] = groups[date] || [];
    groups[date].push(task);
    return groups;
  }, {});


  const fetchUserRank = async () => {
    try {
      const response = await axios.get(API_ENDPOINT + `/api/users/rank?uid=${uid}`);
      setUserRank(response.data.userRank);
      console.log("Calculating user rank");
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching user rank:', error);
      setIsLoading(false);
    }
  };

  const generateTask = async (e) => {
    if (e){
      e.preventDefault();
    }

    const data = {
      uid: uid
    }

    try {
      const result = await axios.post(API_ENDPOINT + '/api/chatgpt', data);
      //setResponse(result.data.text);
      setResponse(result);

      console.log(result.data);

      const goal = result.data.goal
      console.log("Goal: " + goal);
      const task = result.data.task
      console.log("Task: " + task)

      const task_id = result.data.task_id //tasks.length > 0 ? Math.max(...tasks.map(task => task.id)) + 1 : 1;

      
      // Add the parsed goal and task to the tasks state
      /*
      setTasks([...tasks, {
        id: task_id, // Generate unique ID
        goal,
        task,
        date: today,
        status: 0, // Set default status
      }]);
      */

      const newTask = {
        id: task_id,
        goal,
        task,
        date: today,
        show_again: 1,
        status: 0, // Set default status
      };
  
      // Update the tasks state using spread operator only after successful API call
      const updatedTasks = [...tasks, newTask];
      setTasks(updatedTasks);
      
      
    } catch (error) {
      
      console.log(error.response.data)
      if (error.response.data.error == 'Error finding goal, please try again later.'){
        alert("You do not have any goals (e.g. Lose weight). Add goals on your profile page.")
        window.location = "/profile";
      } else {
        alert(error.response.data.error);
      }
      
      console.error(error);
      console.log('an error occurred');
      //setResponse('An error occurred while processing your request.');
    }
  };

  const generateDailyTasks = async() => {
    for (let i = 0; i < 3; i++) {
      await generateTask();
    }  
    window.location.reload();
  }


  useEffect(() => {
    fetchUserRank();
    fetchTasks();
  }, []);
  

  const handleCompletedTask = async (taskId) => {
    try {

      const response = await axios.post(API_ENDPOINT + '/api/tasks/complete', { uid, taskId });
  
      if (response.data.message === 'Task marked as completed successfully!') {
        // Update local state if the server response is successful
        setTasks(
          tasks.map((task) =>
            task.id === taskId ? { ...task, status: 1 } : task
          )
        );
      } else {
        console.error('Error marking task as completed:', response.data.message);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const fetchTasks = async () => {
    try {
      const response = await axios.get(API_ENDPOINT + `/api/tasks?uid=${uid}`);
  
      if (response.data.length > 0) {

        //console.log(response.data)
        //console.log("status: " + JSON.stringify(response.data))
        
        setTasks([
          ...response.data.map(taskData => ({
            id: taskData.id, // Access individual task's ID
            goal: taskData.goal_text,
            task: taskData.task_text,
            date: taskData.createdAt,
            show_again: taskData.show_again,
            status: taskData.completed, // Set default status
          }))
        ]);
        
        //console.log(JSON.stringify(tasks))

      }
    } catch (error) {
      console.error('Error fetching completed tasks:', error);
    }
  };

  const totalTasks = tasks.length;
  const numCompletedTasks = tasks.filter(task => task.status).length;

  const completionRate = numCompletedTasks / totalTasks * 100; // Calculate percentage


  const rerollTask = () => {

  }
  
  const deleteTask = async (taskId) => {
    try {

      const response = await axios.post(API_ENDPOINT + '/api/tasks/delete', { uid, taskId });
  
      if (response.data.message === 'Task deleted successfully!') {
        // Update local state if the server response is successful
        setTasks(tasks.filter((task) => task.id !== taskId));
      } else {
        console.error('Error marking task as completed:', response.data.message);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const handleGoodTask = () => {
    // Implement the logic for when the user indicates they like a task
    // e.g. record task in DB, and give it a higher weight
  };
  
  const handleAvoidTask = async (taskId) => {
    // Logic for when the user doesn't want to be shown a task again.
    // Avoided tasks can be used as negative prompts for future task generation prompts for a given user
    // e.g. do not record in DB for future tasks to show the user
    // However, we still want to keep this task on record and know it's a bad task.
    try {

      const response = await axios.post(API_ENDPOINT + '/api/tasks/avoid', { uid, taskId });
  
      if (response.data.message === 'We will avoid tasks like this in the future.') {
        // Update local state if the server response is successful
        
        setTasks(
          tasks.map((task) =>
            task.id === taskId ? { ...task, show_again: 0 } : task
          )
        );
        
      } else {
        console.error('Error marking task as avoidable:', response.data.message);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }

  };

  // Logic for when the user wants to allow a task to be shown again.
  const handleAllowTask = async (taskId) => {

    try {

      const response = await axios.post(API_ENDPOINT + '/api/tasks/allow', { uid, taskId });
  
      if (response.data.message === 'We will allow tasks like this in the future.') {
        
        setTasks(
          tasks.map((task) =>
            task.id === taskId ? { ...task, show_again: 1 } : task
          )
        );
        
      } else {
        console.error('Error marking task as allowable:', response.data.message);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }

  };

  


  


  return (
    <div className="container buffer">
      
      <div className="row buffer">  
        <div className="col-xl-7 offset-xl-2 col-md-12">
          {/* Project Summary */}
          <h3>Progress Summary</h3>
            <div className="row project_summary_box">
              <li className="list-group-item col-xl-4">

                  <span>Continuous days:</span>
                  <span>{calculateContinuousDaysFromToday(completedTasks)}</span>

              </li>
              <li className="list-group-item col-xl-4">

                  <span>Task completion rate:</span>
                  <span>{Math.round(completionRate) || 0}%</span>

              </li>
              <li className="list-group-item col-xl-4">

                  <span>Ranking:</span>
                  <span>{userRank}</span>

              </li>
            </div>

        </div>
      </div>
      
      <br />
      <DisplayGoals/>

      <div className="row buffer">
        
        <div className="col-xl-7 offset-xl-2 col-md-12">
          {activeTasks.length === 0 ? 
          <>
          <button className="m-2 btn btn-warning" onClick={generateDailyTasks}>Generate daily tasks</button> 
          </>
          :
          <>
          <button className="m-2 btn btn-warning" onClick={generateTask}>Generate task</button>
          </>
          }


          
          {/* <h3>Tasks</h3> Tasks List */}
          <div className="tasks-list mt-3">
              {activeTasks.length > 0 ? (
                <>
                  <h4>{`Today, ${formatDateMonthDay(today)}`}</h4>
                  {activeTasks.reverse().map((task) => (
                    <div key={task.id} className={`task-item row ${task.show_again==0 ? 'bg-dark bg-gradient' : ''}`}> 
                      <div className="task-details row col-xl-10 col-md-8">
                        <img src="https://cdn3.iconfinder.com/data/icons/glypho-signs/64/user-running-512.png" alt="Task Icon" className="task-image col-xl-2 col-md-1" />
                        <p className="task-name col-xl-9 col-md-4">{task.goal}</p>
                        <p className="task-description col-xl-12 col-md-12 pe-md-5">{task.task}</p>
                      </div>
                      <div className="col-md-11 col-xl-2 row">
                        <button className="m-1 btn btn-success col-3 offset-md-1 col-xl-10 bi-check-lg" title="Complete task" onClick={() => handleCompletedTask(task.id)}></button>
                        {/*<button className="m-1 btn btn-info col-md-8 col-xl-10" onClick={rerollTask}>Reroll</button> */}
                        <button className="m-1 btn btn-danger col-3 col-xl-10 bi-trash-fill" title="Delete task" onClick={() => deleteTask(task.id)}></button>
                        <button className="m-1 btn btn-info col-3 col-xl-10 bi-exclamation-diamond-fill" title="Don't show task again" onClick={() => handleAvoidTask(task.id)}></button>

                      </div>
                    </div>
                  ))}
                  
                </>
              ) : (
                <>
                  <br />
                  <h1>Congratulations!</h1>
                  <h2>You completed your three daily tasks.</h2>
                  <br />
                  <br />
                </>
              )}
            </div>


          <button className="btn btn-secondary m-4" type="button" data-bs-toggle="collapse" data-bs-target="#incomplete-tasks" aria-expanded="false" aria-controls="collapseExample">
            Incomplete Tasks
          </button>

          {
            //If there is at least one incompleted task
            incompleteTasks.length > 0 ? (
         <div className="collapse" id="incomplete-tasks">
              <h3>Incomplete Tasks</h3>

          <div className="tasks-list mt-3">
            {incompleteTasks.reverse().map((task) => (           
              <div key={task.id} className={`task-item row task-incomplete ${task.show_again==0 ? 'bg-dark bg-gradient' : ''}`}>
                <div className="task-details row col-xl-9 col-md-8">
                  <img src={ high_five_icon }  alt="Completed task icon" className="task-image col-xl-2 col-md-1" />
                  <p className="task-name col-xl-9 col-md-4">{task.goal} - {formatDateMonthDay(task.date)}</p>
                  <p className="task-description col-xl-12 col-md-12">{task.task}</p>
                      
                </div>
                <br />
                <div className="col-md-11 col-xl-2 row">
                  {/*<button className="m-1 btn btn-info col-md-8 col-xl-10" onClick={rerollTask}>Reroll</button> */}
                  <button className="m-1 btn btn-danger col-3 col-xl-10 bi-trash-fill" title="Delete task" onClick={() => deleteTask(task.id)}></button>
                  <button className="m-1 btn btn-info col-3 col-xl-10 bi-exclamation-diamond-fill" title="Don't show tasks like this" onClick={() => handleAvoidTask(task.id)}></button>

                </div>
                
              </div>
            ))}
          </div>
              </div>
            ) : (
              <></>
            )}
          
          <button className="btn btn-secondary m-4" type="button" data-bs-toggle="collapse" data-bs-target="#completed-tasks" aria-expanded="false" aria-controls="collapseExample">
            Completed Tasks
          </button>
          {
            //If there is at least one completed task
            tasks.filter((task) => task.status === 1).length > 0 ? (
         <div className="collapse" id="completed-tasks">
              <h3>Completed Tasks</h3>

          <div className="tasks-list mt-3">
            {completedTasks.reverse().map((task) => (           
              <div key={task.id} className={`task-item row task-completed ${task.show_again==0 ? 'bg-dark bg-gradient' : ''}`}>
                <div className="task-details row col-xl-8 col-md-8">
                  <img src={ high_five_icon }  alt="Completed task icon" className="task-image col-xl-2 col-md-1" />
                  <p className="task-name col-xl-9 col-md-4">{task.goal} - {formatDateMonthDay(task.date)}</p>
                  <p className="task-description col-xl-12 col-md-12">{task.task}</p>
                      
                </div>
                <br />
                <div className="col-md-11 col-xl-2 row">
                  {/*<button className="m-1 btn btn-info col-md-8 col-xl-10" onClick={rerollTask}>Reroll</button> */}
                  <button className="m-1 btn btn-danger col-3 col-xl-10 bi-trash-fill" title="Delete task" onClick={() => deleteTask(task.id)}></button>
                  {task.show_again == 1 ? 
                  <button className="m-1 btn btn-info col-3 col-xl-10 bi-exclamation-diamond-fill" title="Don't show tasks like this" onClick={() => handleAvoidTask(task.id)}></button>
                  :
                  <button className="m-1 btn btn-warning col-3 col-xl-10 bi-exclamation-diamond" title="Allow tasks like this" onClick={() => handleAllowTask(task.id)}></button>
                  }
                </div>
                
              </div>
            ))}
          </div>
              </div>
            ) : (
              <></>
            )}



          <button className="btn btn-secondary m-4" type="button" data-bs-toggle="collapse" data-bs-target="#blocked-tasks" aria-expanded="false" aria-controls="collapseExample">
            Blocked Tasks
          </button>
          {
            //If there is at least one completed task
            tasks.filter((task) => task.show_again === 0).length > 0 ? (
         <div className="collapse" id="blocked-tasks">
              <h3>Blocked Tasks</h3>

          <div className="tasks-list mt-3">
            {blockedTasks.reverse().map((task) => (           
              <div key={task.id} className={`task-item row bg-dark bg-gradient`}>
                <div className="task-details row col-xl-8 col-md-8">
                  <img src={ high_five_icon }  alt="Completed task icon" className="task-image col-xl-2 col-md-1" />
                  <p className="task-name col-xl-9 col-md-4">{task.goal} - {formatDateMonthDay(task.date)}</p>
                  <p className="task-description col-xl-12 col-md-12">{task.task}</p>
                      
                </div>
                <br />
                <div className="col-md-11 col-xl-2 row">
                  <button className="m-1 btn btn-warning col-3 col-xl-10 bi-exclamation-diamond" title="Allow tasks like this" onClick={() => handleAllowTask(task.id)}></button>
                  
                </div>
                
              </div>
            ))}
          </div>
              </div>
            ) : (
              <></>
            )}

            <br /><br />
        </div>
      </div>

      
    </div>
  );
};

export default Tasks;
