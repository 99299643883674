import React, { useEffect } from 'react'; // Add useContext here

function Logout(){

  useEffect(() => {

    console.log("Logging out");

    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('first_name');
    localStorage.removeItem('email');
    localStorage.removeItem('user_type');
    localStorage.removeItem('uid');

    window.location = "/login"

  }, []);

  return (
      <div>
          Logging out
      </div>
  );
}

export default Logout;




