import './App.css';
// Create an AuthContext
import React, { useState } from 'react';
import LandingPage from './components/LandingPage';
import About from './components/About';
import Intake from './components/Intake';
import Tasks from './components/Tasks';
import Profile from './components/Profile';
import Privacy from './components/Privacy';
import Navbar from './components/Navbar.js';
import LoginRegisterForm from './components/LoginRegisterForm';
import Logout from './components/Logout';



// Provide AuthContext in a parent component
import { AuthContext } from './components/AuthContext';

import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";



function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState(null);

  const handleSuccessfulLogin = (response_data) => {
    setIsLoggedIn(true);
    setToken(response_data.token);

    localStorage.setItem('isLoggedIn', JSON.stringify(isLoggedIn)); // Convert JS object to string
    localStorage.setItem('token', token);

    localStorage.setItem('first_name', response_data.name);
    localStorage.setItem('email', response_data.email);
    localStorage.setItem('uid', response_data.id);


    console.log("is logged in: " + isLoggedIn );
    console.log("token = " + token);
  };

  const inhabitDummyProfile = () => {

    const dummy_data = {
        "id": 1,
        "name": "Test Account",
        "email": "test@account.com",
        "token": "secure_token",
    }

    handleSuccessfulLogin(dummy_data);
    window.location = "/tasks"
  }

  const isHomePage = window.location.pathname === "/";

  return (
    <AuthContext.Provider value={{ isLoggedIn, token, handleSuccessfulLogin }}>
      <div className={`App ${isHomePage ? 'bg-image-2' : ''}`}>
        <Navbar isHomePage={isHomePage} />

        <Router>
          <Routes>
              <Route exact path="/about" element={<About inhabitDummyProfile={inhabitDummyProfile} />} /> 
              <Route exact path="/intake" element={<Intake />} /> 
              <Route exact path="/tasks" element={<Tasks />} /> 
              <Route exact path="/profile" element={<Profile />} /> 
              <Route exact path="/privacy" element={<Privacy />} /> 
              <Route exact path="/login" element={<LoginRegisterForm inhabitDummyProfile={inhabitDummyProfile} />} /> 
              <Route exact path="/logout" element={<Logout />} /> 

              <Route path="/" element={<LandingPage inhabitDummyProfile={inhabitDummyProfile} />} /> 
          </Routes>
        </Router>
      </div>
    </AuthContext.Provider>
  );
}

export default App;