import React, { useContext } from 'react';
import patient_intake from '../resources/samples/patient_intake.png';
import strategy_photo from '../resources/samples/strategy_photo.png';
import tasks_photo from '../resources/samples/tasks_photo.png';
import { useNavigate } from 'react-router-dom';

function LandingPage({ inhabitDummyProfile }) {

  const navigate = useNavigate(); // Initialize the history object


  return (
    <div className="landing-page">
      <h3 className="header container">
        <p className="tagline">Personalized action plans to build healthy habits every day.</p>
      </h3>

      {/* formerly: Personalized action plans to target risk areas and increase longevity.*/}
      
      <div className="">
        <button className="learn-more btn btn-primary btn-lg" onClick={() => window.location = "/login"}>Get Started</button>
      </div>

      <br /><br />
      <div className="container">Not ready to register? Try out one of our test profiles <b><a className="" onClick={() => inhabitDummyProfile()}>here</a></b>.</div>

      <br />

      <br />

      <div className="mb-5">
        <button className="learn-more btn btn-info" onClick={() => window.location = "/about" }>Learn more</button>
      </div>

      <a className="mt-5 mb-5" href="https://apps.apple.com/us/app/threedaily/id6499478691?itsct=apps_box_badge&amp;itscg=30200" style={{ display: 'inline-block', overflow: 'hidden', borderRadius: '13px', width: '200px', height: '68px' }}>
        <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1714608000" alt="Download on the App Store" style={{ borderRadius: '13px', width: '200px', height: '68px' }}/>
      </a>



      {/* 

      <div className="features buffer">
        <div className="feature d-flex align-items-center justify-content-center">
          <p className="description m-2 col-6 col-offset-3"><h3>Intake</h3> That form you fill out whenever you go to the doctor or dentist? We'll need that info too. But hopefully we've made it easier for you.</p>
          <img className="m-2 col-4" src={ patient_intake } alt="Step 1" />
        </div>
        <div className="feature d-flex align-items-center justify-content-center">
          
          <p className="description m-2 col-6"><h3>Strategize</h3> Based on the information you provide, we'll be able to procedurally identify your risk areas, preventative procedures to be aware of (and perhaps accelerate), and create a long term strategy to minimize your personal health risks.</p>
          <img className="m-2 col-4 image-container" src={ strategy_photo } alt="Step 2" />
        </div>
        <div className="feature d-flex align-items-center justify-content-center">

          <p className="description m-2 col-6"><h3>Personalized assistance</h3> If you like our proposed risk-mitigation strategy, we can provide you with daily personalized tasks that help you build habits to specifically target your risk areas and improve your chances of avoiding specific ailments.</p>
          <img className="m-2 col-4 image-container" src={ tasks_photo } alt="Step 3" />
        </div>

      </div>

      */}
    </div>
  );
}

export default LandingPage;
