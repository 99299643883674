import React from 'react'; // Add useContext here
import lifetask_logo from '../resources/lifetask_logo_small.svg';

function Navbar({ isHomePage }) {
  const isLoggedIn = localStorage.getItem('isLoggedIn');
  const email = localStorage.getItem('email') || '';

  const emailPrefix = email.split('@')[0];

  // Capitalizing the first letter and lowercase the rest
  const formattedEmail = emailPrefix.charAt(0).toUpperCase() + emailPrefix.slice(1).toLowerCase();


  return (

    
      <nav className={`navbar navbar-dark navbar-expand-sm ${isHomePage ? 'navbar-landing' : 'navbar-general'}`}>
        <div className="container-fluid">  {/* Wrap navbar content in container-fluid */}
          <div className="nav-bar-logo">
            <a className="lifetask-logo-text" href="/">
              <img className="nav-logo" src={lifetask_logo} alt="Lifetask Logo" /> Three<span className="lifetask-logo-studio-text">Daily</span>
            </a>
          </div>

          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="nav-links ms-auto navbar-nav mb-2 mb-lg-0">  {/* Add ms-auto for right-alignment on larger screens */}
              <li><a className="top-level-nav-item nav-item btn" href="/about">About</a></li>
              {isLoggedIn ? (
                <>
                  <li><a className="top-level-nav-item nav-item btn" href="/tasks">Daily Tasks</a></li>
                  <li className="nav-item dropdown">
                    <a className="btn btn-secondary dropdown-toggle" href="#" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Welcome, { formattedEmail }
                    </a>

                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                      <a className="dropdown-item" href="/profile">Profile</a>
                      <a className="dropdown-item" href="/settings">Settings</a>
                      <a className="dropdown-item" href="/logout">Logout</a>
                    </ul>
                  </li>
                </>
              ) : (
                <>
                  <li><a className="top-level-nav-item btn" href="/login">Login</a></li>
                  <li><a className="btn btn-secondary" href="/login">Get Started</a></li>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
  );
}

export default Navbar;
