import React from 'react';

function About({ inhabitDummyProfile }) {
  return (
    <div className="bg-image-1">
      <div className="about-page container">
        <h1 className="header">
          <p className="tagline">Activate your health</p>
        </h1>

        <div className="about-text">
          <p className="h2 description bold">About</p>
          <br />
          <div className="mission">
            <p className="description bold lead">
              ThreeDaily was created by a team of doctors and engineers to help people lead happier and healthier lives.
              
              {/*     
                At ThreeDaily our mission is to make the healthcare system more interactive and accessible to patients and increase patient involvement in daily personal health maintenance.
              */} 
          </p>

          <br />

          <p>
            Why does the United States have state of the art medical care and trillions of spending in drug and medical development, and still some of the worst health outcomes among peer countries?
          </p>

          <p className="description"> 
            Only 20% of patient outcomes are impacted by the medical system, which skews focus towards reactive and late-stage care. The majority of patient outcomes are determined by daily behaviors at home and in everyday life. ThreeDaily helps you activate healthy habits and make progress towards your health goals every day.
          </p>
          </div>

          <div className="vision">
            <p className="description">
              We believe that everyone should have access to a full profile of their health information that can be leveraged to identify and mitigate personal health risk factors through habit-forming, actionable and hyper-personalized activities. 
            </p>
          </div>
        </div>

        <br /><br />


        <div className="">
          <button className="learn-more btn btn-light btn-lg" onClick={() => window.location = "/login"}>Get Started</button>
        </div>

        <br /><br />


        <div className="about-text">
          <p className="h2 description bold">How it works</p>

          <br />

          <p className="description">
          50% of health outcomes are determined by daily behaviors. 
          </p>
          <p className="description">
          Tasks are actionable whereas long term goals are conceptual. Tasks convert long term goals into things you can do today. 
          </p>
          <p className="description">
          Understanding your life and the things around you improves the opportunity to provide relevant tasks you’re able and willing to accomplish.
          </p>
        </div>

        <br /><br />

        <div className="about-text">
          <p className="h2 description bold">Why hyper-personalized tasks?</p>

          <br />

          <p className="description">
          50% of health outcomes are determined by daily behaviors. 
          </p>
          <p className="description">
          Tasks are actionable whereas long term goals are conceptual. Tasks convert long term goals into things you can do today. 
          </p>
          <p className="description">
          Understanding your life and the things around you improves the opportunity to provide relevant tasks you’re able and willing to accomplish.
          </p>
        </div>

        <br />
        
        <div className="">Not ready to register? Try out one of our test profiles <b><a className="" onClick={() => inhabitDummyProfile()}>here</a></b>.</div>

        <br />



      </div>


    </div>
  );
}

export default About;
