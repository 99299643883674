import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Import axios for HTTP requests

function Profile() {

  const API_ENDPOINT = process.env.REACT_APP_SERVER_HOST || 'http://localhost:9090'

  const [newGoal, setNewGoal] = useState('');

  const [goals, setGoals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const uid = localStorage.getItem('uid');
  const [email, setEmail] = useState(localStorage.getItem('email'));
  const [dateOfBirth, setDateOfBirth] = useState(new Date(1990, 1, 20).toISOString().slice(0, 10));
  const [sex, setSex] = useState('');
  const [height, setHeight] = useState('5\'5');
  const [weight, setWeight] = useState('');
  const [city, setCity] = useState(''); // State for city input
  const [jobTitle, setJobTitle] = useState(''); // State for job title input
  const [company, setCompany] = useState(''); // State for company input (optional)
  const [relationshipStatus, setRelationshipStatus] = useState(''); // State for relationship status
  const [parentalStatus, setParentalStatus] = useState(''); // State for parental status
  const [personalityType, setPersonalityType] = useState(''); 
  const [additionalInformation, setAdditionalInformation] = useState('');

  useEffect(() => {
    const fetchGoals = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await axios.get(API_ENDPOINT + `/api/goals?uid=${uid}`);
        setGoals(response.data.goals);
        console.log(response.data);
      } catch (error) {
        console.error('Error fetching goals:', error);
        setError('An error occurred while fetching goals. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    if (uid) {
      fetchGoals();  // Fetch goals when email is available
    }
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'new_goal':
        setNewGoal(value);
        break;
      case 'email':
        console.log("Email: " + value)
        setEmail(value);
        break;
      case 'dob':
        console.log("dob: " + value)
        setDateOfBirth(value);
        break;
      case 'sex':
        console.log("sex: " + value)
        setSex(value);
        break;
      case 'height':
        console.log("height: " + value)
        setHeight(value);
        break;
      case 'weight':
        console.log("weight: " + value)
        setWeight(value);
        break;       
      case 'city':
        setCity(value);
        break;
      case 'jobTitle':
        setJobTitle(value);
        break;
      case 'company':
        setCompany(value); // Optional field
        break;
      case 'relationshipStatus':
        console.log("Rel: " + value)
        setRelationshipStatus(value);
        break;
      case 'parentalStatus':
        console.log("Par: " + value)
        setParentalStatus(value);
        break;
      case 'personalityType':
        console.log("Personality type: " + value)
        setPersonalityType(value);
        break;
      case 'additionalInformation':
        setAdditionalInformation(value);
        break;
      default:
        break;
    }
  };

  const goalOptions = [
    'Be more active',
    'Get healthy sleep',
    'Improve cardiovascular health',
    'Improve flexibility',
    'Improve posture',
    'Lose weight',
    'Lower cholesterol',
    'Manage blood pressure',
    'Manage blood sugar',
    'Quit tobacco',
    'Reverse prediabetes'
  ];

  const relationshipOptions = [
    '',
    'Single',
    'Married',
    'In a Relationship',
    'Divorced',
    'Widowed',
    'Complicated'
  ];

  const parentalOptions = [
    '',
    'No Children',
    'Have Children',
    'Planning to Have Children',
  ];




  const generateHeightOptions = () => {
    const options = [];
    for (let feet = 4; feet < 7; feet++) {
      for (let inches = 0; inches <= 11; inches += 1) { // Iterate every 6 inches
        const height_value = `${feet}'${inches ? inches : ''}`;
        options.push(<option key={height_value} value={height_value}>{height_value}</option>);
      }
    }
    return options;
  };

  async function deleteGoal(goalId) {

    try {
      const response = await axios.delete(API_ENDPOINT + `/api/delete-goal/${goalId}`, {
        data: { uid: uid } // Send user email in request body
      });
  
      if (response.status !== 200) {
        console.error('Error deleting goal:', response.statusText);
        // Handle errors appropriately (e.g., display an error message to the user)
      } else {
        console.log('Goal deleted successfully!');
        window.location.reload()
        // Update UI to reflect the deleted goal (e.g., remove goal from the list)
      }
    } catch (error) {
      console.error('Error during goal deletion:', error);
      // Handle errors appropriately (e.g., display an error message to the user)
    }

    
  }


  const handleGoalSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    const newGoal = event.target.value;

    const goal_data = {
      uid,
      newGoal
    }

    console.log("New goal is: " + newGoal);

    try {
      const response = await axios.post(API_ENDPOINT + '/api/add-goal', goal_data); // Send POST request

      console.log('Goals submitted:', response.data);
      // Handle successful submission (e.g., clear form, show success message)
      window.location.reload(); // Reload the page on success

    } catch (error) {
      console.error('Error submitting goals:', error);
      // Handle errors (e.g., display error message to user)
    }

  };

  const handleUserDetailsSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    const userData = {
      uid,
      email,
      dateOfBirth,
      sex,
      height,
      weight,
      city,
      jobTitle,
      company,
      relationshipStatus,
      parentalStatus,
      personalityType,
      additionalInformation,
    };

    console.log("user ID is: " + uid);
    console.log("userdata is: " + JSON.stringify(userData));

    try {
      const response = await axios.post(API_ENDPOINT + '/api/users', userData); // Send POST request
      console.log('User data submitted:', response.data);
      // Handle successful submission (e.g., clear form, show success message)
      window.location="/tasks"
    } catch (error) {
      console.error('Error submitting user data:', error);
      // Handle errors (e.g., display error message to user)
    }
  };

  useEffect(() => {

    if(uid==1){
      window.location = "/tasks"
      //alert("Test User account cannot access the profile page.")
    }
    
    const fetchUserData = async () => {
      const response = await axios.get(API_ENDPOINT + `/api/users/data?uid=${uid}`);
      // Update state with fetched data
      console.log(response.data)
      setDateOfBirth(response.data.date_of_birth || new Date(1990, 1, 20).toISOString().slice(0, 10));
      setSex(response.data.sex || '');
      setHeight(response.data.height || '');
      setWeight(response.data.weight || '');
      setCity(response.data.city || '');
      setJobTitle(response.data.job_title || '');
      setCompany(response.data.company || '');
      setRelationshipStatus(response.data.relationship_status || '');
      setParentalStatus(response.data.parental_status || '');
      setPersonalityType(response.data.personality_type || '');
      setAdditionalInformation(response.data.additional_information || '');
    };
  
    if (uid) {
      fetchUserData();
    }
    
  }, []);


  return (
    <div className="about-page container">
    {isLoading ? (
      <p>Loading goals...</p>
    ) : error ? (
      <p className="error">{error}</p>
    ) : (
      <ul className="list-group goal-list offset-xl-3 row col-xl-5 col-sm-12">
        {goals.map((goal, index) => ( 
          <li className="list-group-item" key={goal.id}><span className="col-sm-1">{index+1}</span><span className="col-xl-7 col-l-6 col-md-5 col-sm-8">{goal.goal}</span><button className="btn btn-secondary col-xl-3 col-lg-3 col-md-4 col-sm-3" onClick={() => deleteGoal(goal.id)}>Delete</button></li>          
        ))}
      </ul>
    )}

    <br />

    <div className="goals-form offset-xl-3 col-xl-5 col-sm-12">
      <form className="row" onSubmit={handleGoalSubmit}>
    


      <select className="form-select col" id="new_goal" name="new_goal" required onChange={handleGoalSubmit}>
        <option value="">Select a goal</option>
        {goalOptions.map((goal, index) => (
          <option key={index} value={goal}>{goal}</option>
        ))}
      </select>

      {/* 
        <input type="text" className="form-control col" id="new_goal" placeholder="Add a new goal" name="new_goal" required onChange={handleInputChange}/>
      


        <button type="submit" className="btn btn-primary col-xl-2">Submit</button>
      */}
      </form>
    </div>

    <br /><br />


      <h4>Update your account details</h4>
      <div className="intake-form">
        <form onSubmit={handleUserDetailsSubmit}>

          {/*
          <div className="form-group">
            <label htmlFor="input-email">Email address</label>
            <input type="email" className="form-control" id="email" name="email" value={email} aria-describedby="emailHelp" placeholder="Enter email" onChange={handleInputChange}/>
            <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
          </div>

          */}

          <br />

          <div className="row">
            <div className="form-group col-md-3">
              <label htmlFor="dob">Date of Birth</label>
              <input type="date" className="form-control" id="dob" name="dob" min="1930-12-31" max="2010-12-31" onChange={handleInputChange} value={dateOfBirth} />
            </div>

            <br />

            <div className="form-group col-md-3">

            <label htmlFor="relationshipStatus">Sex</label>
            <select
              className="form-control"
              name="sex"
              id="sex"
              value={sex}
              onChange={handleInputChange}
            >
                <option key="" value=""></option>
                <option key="Male" value="Male">Male</option>
                <option key="Female" value="Female">Female</option>
            </select>
            </div>
          </div>

          <br />
          <br />

          <div className="row">
            <div className="col">
              <label htmlFor="height-select">Height</label>
              <select
                className="form-control"
                id="height-select"
                name="height"
                value={height}
                onChange={handleInputChange}
              >
                {generateHeightOptions()}
              </select>
            </div>

            <div className="col">
              <label htmlFor="weight-select">Weight (lbs)</label>
              <input type="number" className="form-control" id="weight-select" name="weight" value={weight} placeholder="130" min="60" max="800" onChange={handleInputChange}/>
            </div>

            <div className="col">
            <label htmlFor="relationshipStatus">Relationship Status</label>
            <select
              className="form-control"
              name="relationshipStatus"
              id="relationshipStatus"
              value={relationshipStatus}
              onChange={handleInputChange}
            >
              {relationshipOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>

          <div className="col">
            <label>Parental Status</label>
            <select
              className="form-control"
              name="parentalStatus"
              id="parentalStatus"
              value={parentalStatus}
              onChange={handleInputChange}
            >
              {parentalOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div> 
          </div>

          <br />

        <div className="row">
          <div className="col-md-3 col-sm-6">
            <label htmlFor="city">City</label>
            <input
              className="form-control"
              type="text"
              name="city"
              id="city"
              value={city}
              onChange={handleInputChange}
            />
          </div>

          <div className="col-md-3 col-sm-6">
            <label htmlFor="jobTitle">Job Title</label>
            <input
              className="form-control"
              type="text"
              name="jobTitle"
              id="jobTitle"
              value={jobTitle}
              onChange={handleInputChange}
            />
          </div>

          <div className="col-md-3 col-sm-12">
            <label htmlFor="company">Company</label>
            <input
              className="form-control"
              type="text"
              name="company"
              id="company"
              value={company} // Optional field
              onChange={handleInputChange}
            />
          </div>

          <div className="col-md-3 col-sm-12">
            <label htmlFor="company">Personality Type</label>
            <select
              className="form-control"
              name="personalityType"
              id="personalityType"
              value={personalityType}
              onChange={handleInputChange}
            >
                <option key="" value=""></option>
                <option key="INFP" value="INFP">INFP</option>
                <option key="INTJ" value="INTJ">INTJ</option>
                <option key="INFJ" value="INFJ">INFJ</option>
                <option key="INTP" value="INTP">INTP</option>
                <option key="ENFP" value="ENFP">ENFP</option>
                <option key="ENTJ" value="ENTJ">ENTJ</option>
                <option key="ENTP" value="ENTP">ENTP</option>
                <option key="ENFJ" value="ENFJ">ENFJ</option>
                <option key="ISFJ" value="ISFJ">ISFJ</option>
                <option key="ISFP" value="ISFP">ISFP</option>
                <option key="ISTJ" value="ISTJ">ISTJ</option>
                <option key="ISTP" value="ISTP">ISTP</option>
                <option key="ESFJ" value="ESFJ">ESFJ</option>
                <option key="ESFP" value="ESFP">ESFP</option>
                <option key="ESTJ" value="ESTJ">ESTJ</option>
                <option key="ESTP" value="ESTP">ESTP</option>
          
  
            </select>
          </div>
        </div>



{/* 
          <div className="form-check form-check-inline">
            <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"/>
            <label className="form-check-label" htmlFor="inlineCheckbox1">1</label>
          </div>
          <div className="form-check form-check-inline">
            <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"/>
            <label className="form-check-label" htmlFor="inlineCheckbox2">2</label>
          </div>
          <div className="form-check form-check-inline">
            <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"/>
            <label className="form-check-label" htmlFor="inlineCheckbox2">2</label>
          </div>
          <div className="form-check form-check-inline">
            <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"/>
            <label className="form-check-label" htmlFor="inlineCheckbox2">2</label>
          </div>
          <div className="form-check form-check-inline">
            <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"/>
            <label className="form-check-label" htmlFor="inlineCheckbox2">2</label>
          </div>
          <div className="form-check form-check-inline">
            <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"/>
            <label className="form-check-label" htmlFor="inlineCheckbox2">2</label>
          </div>
          <div className="form-check form-check-inline">
            <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"/>
            <label className="form-check-label" htmlFor="inlineCheckbox2">2</label>
          </div>
*/}

          <br />
          <br />

          <div className="form-group">
            <label htmlFor="exampleFormControlTextarea1">Is there anything else we should know?</label>
            <textarea className="form-control" 
            id="additionalInformation" 
            name="additionalInformation"
            value={additionalInformation}
            onChange={handleInputChange}
            placeholder="e.g. I am a vegan.
            I prefer activities I can do with my family. 
            I prefer tasks I can do at home. 
            I'd like to get out more. 
            I don't like to cook." 
            rows="3"></textarea>
          </div>

          <br />

          <div className="form-check">
            <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
            <label className="form-check-label" htmlFor="exampleCheck1">I acknowledge that ThreeDaily cannot make me live forever and that all generated strategies and tasks are merely suggestions with no guaranteed outcome.</label>
          </div>

          <br />

          <button type="submit" className="btn btn-primary">Submit</button>
        </form>
      </div>

    </div>
  );
}

export default Profile;
