import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { AuthContext } from './AuthContext';

const LoginRegisterForm = ({ inhabitDummyProfile }) => {

  const API_ENDPOINT = process.env.REACT_APP_SERVER_HOST || 'http://localhost:9090'

  const { handleSuccessfulLogin } = useContext(AuthContext);

  const [formData, setFormData] = useState({
    name: '', // Used for registration
    email: '',
    password: '',
    user_type: 'owner', // Default user type (optional)
  });
  const [isLogin, setIsLogin] = useState(true); // True for login, False for registration

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const url = isLogin ? '/api/login' : '/api/register';

      const response = await axios.post(API_ENDPOINT + url, formData, {
        headers: { 'Content-Type': 'application/json' }});

      if (response.status === 201) { // Successful registration
        // Handle successful registration (e.g., display message, redirect to login)
        console.log('Registration successful!');
        console.log("response is " + JSON.stringify(response.data));
        handleSuccessfulLogin(response.data); // Pass token to parent component
        setIsLogin(true); // Switch to login form
        window.location = "/profile"
      } else if (response.status === 200) { // Successful login
        console.log("logged in successfully");
        console.log("response is " + JSON.stringify(response.data));
        handleSuccessfulLogin(response.data); // Pass token to parent component
        window.location = "/profile"
      } else {
        console.error('Error:', response.data.message);
        // Handle login/registration errors (e.g., display error message)
      }
    } catch (error) {
      console.error('Error:', error);
      // Handle network or other errors
    } finally {
      setFormData({ ...formData, password: '' }); // Clear password after submit
    }
  };

  const toggleForm = () => {
    setIsLogin(!isLogin);
    setFormData({ ...formData, name: '', password: '' }); // Clear form data
  };

  useEffect(() => {

    if(localStorage.getItem('uid')){
      //If user is logged in, don't let them access the login page
      window.location = "/profile"
    }
  });

  return (
    <div className="container login-form">
      <br />
      <br />

      {isLogin ? (
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="form-group col-md-4 col-sm-12">
              <label htmlFor="email">Email address</label>
              <input className="form-control" type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
            </div>

            <div className="form-group col-md-4 col-sm-12">
              <label htmlFor="password">Password</label>
              <input className="form-control" type="password" id="password" name="password" value={formData.password} onChange={handleChange} required />
            </div>
          </div>

          <br />

          <button className="btn btn-primary" type="submit">Login</button>

          <br />
          <br />

          <p>
            Don't have an account? <br />
            <button className="btn btn-secondary" onClick={toggleForm}>Register</button>
          </p>
        </form>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="row">
            {/* 
            <div className="form-group col-md-4 col-sm-12">
              <label htmlFor="name">First name</label>
              <input className="form-control" type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
            </div>
            */}

            <div className="form-group col-md-4 col-sm-12">
              <label htmlFor="email">Email address</label>
              <input className="form-control" type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
            </div>

            <div className="form-group col-md-4 col-sm-12">
              <label htmlFor="password">Password</label>
              <input className="form-control" type="password" id="password" name="password" value={formData.password} onChange={handleChange} required />
            </div>
          </div>

          <br />

          <button className="btn btn-primary" type="submit">Register</button>

          <br />
          <br />

          <p className="form-group">
            Already have an account?
            <br />
            <button className="btn btn-secondary" onClick={toggleForm}>Login</button>
          </p>
        </form>
      )}

        <br />
        
        <div className="">Not ready to register? Try out one of our test profiles <b><a className="" onClick={() => inhabitDummyProfile()}>here</a></b>.</div>

        <br />
    </div>
  );
};

export default LoginRegisterForm;