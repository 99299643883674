import React from 'react';

function Privacy() {
  return (
    <div className="privacy">
      <div className="container py-5">
        <h1 className="h2">Privacy Policy</h1>
        <p className="lead">Effective Date: 04/28/2024</p>

        <section className="mb-5">
          <h2>1. Introduction</h2>
          <p className="text-muted">
            ThreeDaily operates the ThreeDaily application (the "App"). This Privacy Policy describes our practices regarding the collection, use, and disclosure of your information when you use the App and the choices you have associated with that information.
          </p>
        </section>

        <section className="mb-5">
          <h2>2. Information We Collect</h2>
          <p>We collect several types of information for different purposes to improve our service to you.</p>

          <h3>A. Optional Information</h3>
          <p>The App allows you to optionally provide the following information to create a more personalized experience:</p>
          <ul>
            <li>Health Information: This may include data such as weight, height, age, sex, activity levels, dietary habits, and sleep patterns (all optional).</li>
            <li>Personal Information: This may include your email address, and optional information such as your name, family status, job title and additional information you volunteer.</li>
          </ul>
          <p>You are not required to provide any of this information to use the App. However, by providing this information, you consent to our collection and use of the information as described in this Privacy Policy.</p>

          <h3>B. Non-Personally Identifiable Information</h3>
          <p>We may also collect non-personally identifiable information ("Non-PII") when you use the App. This information may include:</p>
          <ul>
            <li>Device information (model, operating system)</li>
            <li>Usage data (features accessed, frequency of use)</li>
          </ul>
          <p>We use this Non-PII to understand how users interact with the App and to improve our services.</p>
        </section>

        <section className="mb-5">
          <h2>3. How We Use the Information</h2>
          <p>We use the information we collect for the following purposes:</p>
          <ul>
            <li>To provide and operate the App, including generating personalized tasks based on your optional health information and personal information (if provided).</li>
            <li>To improve and personalize the App for you.</li>
            <li>To understand how users interact with the App.</li>
            <li>To communicate with you, such as responding to your inquiries and sending you information about the App.</li>
          </ul>
        </section>

        <section className="mb-5">
          <h2>4. Sharing User Information</h2>
          <p>We may share your information with third-party service providers who help us operate the App and generate personalized tasks. These service providers are obligated to use your information only for the purposes we disclose to them.</p>
          <p>We will not share your information with any other third parties without your consent, except as required by law.</p>
        </section>

        <section className="mb-5">
          <h2>5. User Choices and Data Control</h2>
          <p>You are not required to provide any information to use the App. You can choose not to provide any optional information, such as health information or personal information.</p>
          <p>You can also access and update your information by contacting us at [Your Email Address].</p>
        </section>

        <section className="mb-5">
          <h2>6. Data Security</h2>
          <p>We use commercially reasonable measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no internet or electronic storage system is 100% secure. Therefore, we cannot guarantee the absolute security of your information.</p>
        </section>

        <section className="mb-5">
          <h2>7. Children's Privacy</h2>
          <p>Our App is not directed to children under the age of 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us. If we become aware that we have collected personal information from a child under 13, we will take steps to delete that information.</p>
        </section>

        <section className="mb-5">
          <h2>8. Changes to Privacy Poicy</h2>
          <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
        </section>


      </div>
    </div>
  );
}

export default Privacy;
