import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Import axios for HTTP requests

function DisplayGoals() {

  const API_ENDPOINT = process.env.REACT_APP_SERVER_HOST || 'http://localhost:9090'


  const [goals, setGoals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const uid = localStorage.getItem('uid');


  useEffect(() => {
    const fetchGoals = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await axios.get(API_ENDPOINT + `/api/goals?uid=${uid}`);
        setGoals(response.data.goals);
        //console.log(response.data);
      } catch (error) {
        console.error('Error fetching goals:', error);
        setError('An error occurred while fetching goals. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    if (uid) {
      fetchGoals();  // Fetch goals when email is available
    }
  }, []);






  return (

    <div className="">
 
      <div className="col-md-7 offset-md-2 col-sm-12">
        <h4>Your goals</h4>
      </div>

      {isLoading ? (
        <p>Loading goals...</p>
      ) : error ? (
        <p className="error">{error}</p>
      ) : (
        <ul className="list-group goal-list offset-md-2 row col-md-7 col-sm-12">
          {goals.map((goal, index) => ( 
            <li className="list-group-item" key={goal.id}><span className="col-sm-1">{index+1}</span><span className="col-sm-11">{goal.goal}</span></li>          
          ))}
        </ul>
      )}

    </div>
  );
}

export default DisplayGoals;
